import React from "react";

export const plusIcon = {
	light: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fillRule="evenodd"
			clipRule="evenodd"
		>
			<path d="M11 11v-11h1v11h11v1h-11v11h-1v-11h-11v-1h11z" />
		</svg>
	),
	regular: (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
		</svg>
	),
};

export const minusIcon = {
	light: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fillRule="evenodd"
			clipRule="evenodd"
		>
			<path d="M0 12v1h23v-1h-23z" />
		</svg>
	),
	regular: (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path d="M0 10h24v4h-24z" />
		</svg>
	),
};

export const checkboxIcon = {
	active: (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path d="M0 0v24h24v-24h-24zm10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" />
		</svg>
	),
	inactive: (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z" />
		</svg>
	),
};

export const linkIcon = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fillRule="evenodd"
		clipRule="evenodd"
	>
		<path d="M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z" />
	</svg>
);

export const blockSeparator = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 378">
		<path
			fill="none"
			d="M-57.335 281.843c12.824-30.598 67.156-94.362 181.898-104.631 143.426-12.836 286.177-48.396 309.087-63.304 23.119-15.043-.985-23.125-11.009-15.591-17.457 13.121 63.217 38.027 501.479 21.394 350.61-13.306 535.34 73.406 583.88 118.425"
		/>
	</svg>
);

export const formMessagesIcon = {
	success: (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" />
		</svg>
	),
	error: (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" />
		</svg>
	),
};
