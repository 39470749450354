import React, { useEffect, useRef, useState } from "react";
import { slugify, className } from "../../../utils/helpers";
import useWindowWidth from "../../../hooks/useWindowWidth";
import useEventListener from "../../../hooks/useEventListener";
import { linkIcon } from "../../../utils/icons";
import * as styles from "./StickyMenu.module.scss";

const StickyMenu = ({ tags }) => {
	const isDesktop = useWindowWidth(1199);
	const timeout = useRef(null);
	const stickyMenu = useRef(null);
	const mobileToggler = useRef(null);
	const [titles, setTitles] = useState(null);
	const [activeItem, setActiveItem] = useState(0);
	const [menuVisible, setMenuVisibility] = useState(false);

	const getTitles = () => {
		const { parentElement } = stickyMenu.current;
		const titles = parentElement.querySelectorAll(tags);
		return [...titles];
	};

	const debounceSetActiveItem = (item) => {
		clearTimeout(timeout?.current);
		timeout.current = setTimeout(() => setActiveItem(item), 100);
	};

	const getMenu = () => {
		return isDesktop ? stickyMenu.current : mobileToggler.current;
	};

	const setSelectedItem = () => {
		const menu = getMenu();

		if (!menu) return;

		const menuRect = menu.getBoundingClientRect();

		getTitles().forEach((title, i) => {
			const titleRect = title.getBoundingClientRect();

			if (window.scrollY === 0) debounceSetActiveItem(0);
			else if (titleRect.top <= menuRect.bottom) debounceSetActiveItem(i);
		});
	};

	const toggleMenuVisibility = () => {
		const menu = getMenu();

		if (!menu) return;

		const parentRect = menu.parentElement.getBoundingClientRect();
		const menuRect = menu.getBoundingClientRect();

		if (menuRect.bottom > parentRect.bottom)
			menu.classList.add(styles.notVisible);
		else menu.classList.remove(styles.notVisible);
	};

	const handlePointerDown = (e) => {
		if (
			!stickyMenu?.current.contains(e.target) &&
			e.target !== mobileToggler?.current
		)
			setMenuVisibility(false);
	};

	const handleScroll = () => {
		setSelectedItem();
		toggleMenuVisibility();
	};

	useEffect(() => {
		if (!stickyMenu?.current || titles) return;

		setTitles(getTitles().map((x) => x.innerText));
		setSelectedItem();
		toggleMenuVisibility();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEventListener("scroll", handleScroll);
	useEventListener("click", handlePointerDown);

	return (
		<>
			<div
				ref={stickyMenu}
				{...className(styles.stickyMenu, {
					[styles.isVisible]: menuVisible,
				})}
			>
				{titles?.map((title, i) => (
					<a
						key={i}
						href={`#${slugify(title)}`}
						onClick={() => setMenuVisibility(false)}
						{...className({
							[styles.active]: activeItem === i,
						})}
					>
						{title}
					</a>
				))}
			</div>

			{!isDesktop && (
				<button
					ref={mobileToggler}
					className={styles.mobileToggler}
					onClick={() => setMenuVisibility(!menuVisible)}
				>
					{linkIcon}
				</button>
			)}
		</>
	);
};

export default StickyMenu;
