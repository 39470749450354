import React from "react";
import { className } from "../../../utils/helpers";
import { quoteMarks } from "../../../utils/shapes";
import * as styles from "./Quote.module.scss";

const Quote = ({ text, author, withQuoteMarks = true }) => (
	<blockquote className={styles.quote}>
		{withQuoteMarks && (
			<div {...className(styles.quoteMarks, styles.left)}>
				{quoteMarks}
			</div>
		)}

		<div className={styles.text}>
			{typeof text === "object" ? (
				text.map((x, i) => <p key={i}>{x.text}</p>)
			) : (
				<p>{text}</p>
			)}

			{!author && <div className={styles.separator} />}

			{author && (
				<p className={styles.author}>
					<b>{author}</b>
				</p>
			)}
		</div>

		{withQuoteMarks && (
			<div {...className(styles.quoteMarks, styles.right)}>
				{quoteMarks}
			</div>
		)}
	</blockquote>
);

export default Quote;
