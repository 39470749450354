import React from "react";
import { graphql } from "gatsby";
import { className, slugify } from "../utils/helpers";
import { blockSeparator } from "../utils/icons";
import { arrowShape } from "../utils/shapes";
import Seo from "../components/shared/Seo";
import Quote from "../components/shared/Quote";
import StickyMenu from "../components/shared/StickyMenu";
import * as styles from "./styles/HistoryPage.module.scss";

const HistoryPage = ({ data }) => {
	const { data: pageData } = data.allPrismicHistory.edges[0].node;

	return (
		<>
			<Seo
				title={pageData.title.text}
				description={pageData.description.text}
			/>

			<div
				id={slugify(pageData.main_title.text)}
				{...className(styles.historyPage, "page-content")}
			>
				<h2 className="section-title">{pageData.main_title.text}</h2>

				{pageData.main_content.map((block, i) => (
					<div
						key={i}
						id={slugify(block.title.text)}
						{...className(styles.contentBlock, {
							[styles.imageToRight]: block.image_to_right,
							[styles.withSeparator]: block.has_separator,
							[styles.noTitle]: !block.title.text,
						})}
					>
						{block.has_separator && blockSeparator}

						<div className={styles.image}>
							<img src={block.image.url} alt={block.image.alt} />
							{i === 0 && block.image_to_right && arrowShape}
						</div>

						<div className={styles.text}>
							{block.title.text && (
								<h3 className="block-title">
									{block.title.text}
								</h3>
							)}

							{block.content.raw.map((x, i) => (
								<p key={i}>{x.text}</p>
							))}
						</div>
					</div>
				))}

				<StickyMenu tags="h3" />
			</div>

			<Quote text={pageData.main_quote.raw} withQuoteMarks={false} />
		</>
	);
};

export const query = graphql`
	{
		allPrismicHistory {
			edges {
				node {
					data {
						title {
							text
						}
						description {
							text
						}
						main_title {
							text
						}
						main_content {
							title {
								text
							}
							content {
								raw
							}
							image {
								url
								alt
							}
							image_to_right
							has_separator
						}
						main_quote {
							raw
						}
					}
				}
			}
		}
	}
`;

export default HistoryPage;
